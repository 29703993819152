import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormControl, NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Currency } from 'src/app/Shared/Currency';

@Component({
  selector: 'app-add-station',
  templateUrl: './add-station.component.html',
  styleUrls: ['./add-station.component.css']
})
export class AddStationComponent implements OnInit {

  Machine = new Machine();
  currencyList = Currency;

  createMode =true;
  pass : string;

  modulesList = ['Laser Cutting/Machining', 'Tapping/Drilling', 'Pipe Cutting', 'Bending', 'Fitting/Welding', 'Polishing/Deburring/Buffing/Cleaning', 'Assembly', 'Production Done'];

  constructor(
    private angularFireAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<AddStationComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

    if(data){
      this.createMode  = false;
      this.Machine = data;
    }
  }

  ngOnInit() {}

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.Machine);
  }

  emailFlag= false;
  passFlag= false;


  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  passwordChange(event, model: NgModel) {
    if(model.value.length < 6){
      model.control.setErrors({ invalid: true });
      this.passFlag = true;

    }else{
      this.passFlag = false;

    }
  }
}
