export class Machine {

  private _Machine_No: string;
  private _Machine_Code: string;
  private _MachineStatus: string;
  private _MachineTonnage: string;
  private _Schedule_Track: ScheduleTracker[] = [];
  private _OEE: OEE[];
  public StationName = '';
  public Role = '';
  public Email = '';
  public StationNo = '';
  public StationID = null;
  public password = '';

  public current :boolean = true;
  public Brand: string
  public PreviousScheduleTrack:ScheduleTracker[] = [];
  public AverageOEE: number = 0;
  public MachineSchedule:MachineSchedule[] = [];
  public AverageA: number = 0;
  public AverageP: number = 0;
  public AverageQ: number = 0;
  public Process = [];


  set Machine_No(value: string) {
    this._Machine_No = value;
  }

  get Machine_No(): string {
    return this._Machine_No;
  }

  get Machine_Code(): string {
    return this._Machine_Code;
  }
  set Machine_Code(value: string) {
    this._Machine_Code = value;
  }

  get MachineStatus(): string {
    return this._MachineStatus;
  }
  set MachineStatus(value: string) {
    this._MachineStatus = value;
  }

  get MachineTonnage(): string {
    return this._MachineTonnage;
  }
  set MachineTonnage(value: string) {
    this._MachineTonnage = value;
  }

  get Schedule_Track(): ScheduleTracker[] {
    return this._Schedule_Track;
  }
  set Schedule_Track(value: ScheduleTracker[]) {
    this._Schedule_Track = value;
  }

  get OEE(): OEE[] {
    return this._OEE;
  }
  set OEE(value: OEE[]) {
    this._OEE = value;
  }
}

export class MachineSchedule {
  public ID: string;
  public PO_No: string;
  public JO_No: string;
  public Customer: string;
  public Quantity: number;
  public Part_No: number;
  public Part_Names: number;
  public new: number;
  public bf: number;
  public left: number;
  public complete: number;
  public Billing_Address: string;
  public Delivery_Address: string;
  public Reason : string = '-';
  public Updated_By : string;
  public Updated_Date : Date;
  public Status : string;
  public HandledById : string;
  public HandledBy : string;
}

export class ScheduleTracker {

  private _Machine_Schedule_No: string;
  public Machine_Schedule_No2: string = '';
  public Machine_Schedule_No3: string = '';
  public PartNo2: string = '';
  public PartNo3: string = '';
  public MouldNumber: string = '';
  private _Exp_Qty: number;
  private _Acc_Qty: number;
  private _Machine_Number: string;
  private _Machine_Schedule_Code: string;
  private _Machine_Schedule_PO_No: string;
  private _Machine_Schedule_Part_No: string;
  private _Machine_Schedule_Start_Date: string;
  private _Machine_Schedule_End_Date: string;
  private _DateFormat_Start: Date;
  private _DateFormat_End: Date;
  private _Last_Update: Date;
  private _HourLeft: number;
  private _Machine_Schedule_Status: string;
  private _Active_Schedule_Status: Boolean;
  private _Button_Status: Boolean;
  private _Machine_Progress: number;
  private _PartCavityNum: string;
  private _PredefinedCycleTime: string;
  private _DatePresetting_Start: string;
  private _DatePresetting_End: string;
  private _DateDown_Start: number;
  private _DateDown_End: number;
  private _TotalDownTime: number;
  private _Availability: number;
  private _Performance: number;
  private _Effectiveness: number;
  private _PO_No: string;

  RawMaterialUsed : RawMaterialUsed[]=[]
  RawMaterialNeeded : RawMaterialNeeded[]=[]
  BoxNeeded : RawMaterialNeeded[]=[]

  OpeningStockPart : number = 0;
  POQuantity : string;

  PartName: string = "";
  MouldRefNo: string = "";

  QCDone: boolean = false;
  QCAccepted : number = 0;
  QCRejected : number = 0;
  QCDate: Date;

  OEEDone:boolean = false;
  OEE:number = 0;
  OEE1:number = 0;

  CycleTime : number = 0;
  MaxCycleTime : number = 0;
  MinCycleTime : number = 0;
  QCQuantity : number = 0;

  DownTimeDetail: any = [];


  get Machine_Schedule_No(): string {
    return this._Machine_Schedule_No;
  }
  set Machine_Schedule_No(value: string) {
    this._Machine_Schedule_No = value;
  }

  get Exp_Qty(): number {
    return this._Exp_Qty;
  }
  set Exp_Qty(value: number) {
    this._Exp_Qty = value;
  }

  get Active_Schedule_Status(): Boolean {
    return this._Active_Schedule_Status;
  }
  set Active_Schedule_Status(value: Boolean) {
    this._Active_Schedule_Status = value;
  }

  get Button_Status(): Boolean {
    return this._Button_Status;
  }
  set Button_Status(value: Boolean) {
    this._Button_Status = value;
  }

  get PredefinedCycleTime(): string {
    return this._PredefinedCycleTime;
  }
  set PredefinedCycleTime(value: string) {
    this._PredefinedCycleTime = value;
  }

  get Machine_Number(): string {
    return this._Machine_Number;
  }

  set Machine_Number(value: string) {
    this._Machine_Number = value;
  }

  get Acc_Qty(): number {
    return this._Acc_Qty;
  }
  set Acc_Qty(value: number) {
    this._Acc_Qty = value;
  }

  get Machine_Schedule_Code(): string {
    return this._Machine_Schedule_Code;
  }
  set Machine_Schedule_Code(value: string) {
    this._Machine_Schedule_Code = value;
  }

  get Machine_Schedule_PO_No(): string {
    return this._Machine_Schedule_PO_No;
  }
  set Machine_Schedule_PO_No(value: string) {
    this._Machine_Schedule_PO_No = value;
  }

  get Machine_Schedule_Part_No(): string {
    return this._Machine_Schedule_Part_No;
  }
  set Machine_Schedule_Part_No(value: string) {
    this._Machine_Schedule_Part_No = value;
  }

  get Machine_Schedule_Start_Date(): string {
    return this._Machine_Schedule_Start_Date;
  }
  set Machine_Schedule_Start_Date(value: string) {
    this._Machine_Schedule_Start_Date = value;
  }

  get Machine_Schedule_End_Date(): string {
    return this._Machine_Schedule_End_Date;
  }
  set Machine_Schedule_End_Date(value: string) {
    this._Machine_Schedule_End_Date = value;
  }

  get DateFormatStart(): Date {
    return this._DateFormat_Start;
  }
  set DateFormatStart(value: Date) {
    this._DateFormat_Start = value;
  }

  get DateFormatEnd(): Date {
    return this._DateFormat_End;
  }
  set DateFormatEnd(value: Date) {
    this._DateFormat_End = value;
  }

  get LastUpdate(): Date {
    return this._Last_Update;
  }
  set LastUpdate(value: Date) {
    this._Last_Update = value;
  }

  get HourLeft(): number {
    return this._HourLeft;
  }
  set HourLeft(value: number) {
    this._HourLeft = value;
  }


  get Machine_Schedule_Status(): string {
    return this._Machine_Schedule_Status;
  }
  set Machine_Schedule_Status(value: string) {
    this._Machine_Schedule_Status = value;
  }

  get Machine_Progress(): number {
    return this._Machine_Progress;
  }
  set Machine_Progress(value: number) {
    this._Machine_Progress = value;
  }

  set PartCavityNum(value: string){
    this._PartCavityNum = value;
  }

  get PartCavityNum():string{
    return this._PartCavityNum;
  }

  set PO_No(value: string){
    this._PO_No = value;
  }

  get PO_No():string{
    return this._PO_No;
  }

  get DatePresettingStart(): string {
    return this._DatePresetting_Start;
  }
  set DatePresettingStart(value: string) {
    this._DatePresetting_Start = value;
  }

  get DatePresettingEnd(): string {
    return this._DatePresetting_End;
  }
  set DatePresettingEnd(value: string) {
    this._DatePresetting_End = value;
  }

  get DateDown_Start(): number {
    return this._DateDown_Start;
  }
  set DateDown_Start(value: number) {
    this._DateDown_Start = value;
  }

  get DateDown_End(): number {
    return this._DateDown_End;
  }
  set DateDown_End(value: number) {
    this._DateDown_End = value;
  }

  get TotalDownTime(): number {
    return this._TotalDownTime;
  }
  set TotalDownTime(value: number) {
    this._TotalDownTime = value;
  }

  get Availability(): number {
    return this._Availability;
  }
  set Availability(value: number) {
    this._Availability = value;
  }

  get Performance(): number {
    return this._Performance;
  }
  set Performance(value: number) {
    this._Performance = value;
  }

  get Effectiveness(): number {
    return this._Effectiveness;
  }
  set Effectiveness(value: number) {
    this._Effectiveness = value;
  }
}

export class RawMaterialUsed {
 public MaterialName: string='';
 public MaterialID: string='';
 public Quantity: number=0;
 public BoxNumber: string='';
}

export class RawMaterialNeeded {
  public MaterialName: string='';
  public MaterialID: string='';
  public Quantity: number=0;
 }




export class OEE {
  private _Year: string;
  private _Monthly_OEE: MonthOEE[];

  get Year(): string {
    return this._Year;
  }
  set Year(value: string) {
    this._Year = value;
  }

  get Monthly_OEE(): MonthOEE[] {
    return this._Monthly_OEE;
  }
  set Monthly_OEE(value: MonthOEE[]) {
    this._Monthly_OEE = value;
  }
}

export class MonthOEE {
  private _Month: string;
  private _This_Month_OEE: number;
  private _Total_No_Jobs_Hours: number;
  private _Total_Jobs: number;

  get Month(): string {
    return this._Month;
  }
  set Month(value: string) {
    this._Month = value;
  }

  get This_Month_OEE(): number {
    return this._This_Month_OEE;
  }
  set This_Month_OEE(value: number) {
    this._This_Month_OEE = value;
  }

  get Total_No_Jobs_Hours(): number {
    return this._Total_No_Jobs_Hours;
  }
  set Total_No_Jobs_Hours(value: number) {
    this._Total_No_Jobs_Hours = value;
  }

  get Total_Jobs(): number {
    return this._Total_Jobs;
  }
  set Total_Jobs(value: number) {
    this._Total_Jobs = value;
  }
}
